:root { 
--blue_gray_50:#eaecf0; 
--red_300:#c4847c; 
--amber_A200:#ffda44; 
--gray_50:#f8f9fb; 
--gray_900_0c:#1018280c; 
--blue_gray_500_01:#667084; 
--black_900:#000000; 
--blue_gray_800:#344053; 
--blue_gray_700:#32646c; 
--black_900_28:#00000028; 
--red_A700:#d80027; 
--white_A700_e5:#ffffffe5; 
--blue_900:#0052b4; 
--blue_gray_100:#cfd4dc; 
--blue_gray_300:#98a1b2; 
--gray_900:#0f1728; 
--blue_gray_500:#667085; 
--gray_900_01:#101828; 
--teal_50:#dcf1f1; 
--amber_300:#fec84a; 
--gray_200:#f0f0f0; 
--gray_900_07:#10182807; 
--gray_100:#f2f3f6; 
--gray_100_02:#f5f5f5; 
--gray_100_01:#f1fafa; 
--blue_gray_700_02:#475467; 
--white_A700:#ffffff; 
--blue_gray_700_01:#475466; 
}